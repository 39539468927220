<template>
  <div class="ManagerSalarieDashboard" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Espace administration - Affecter les conseillers</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- ERROR MODAL -->
            <b-modal ref="globalAPILoadingErrorModal" hide-footer title="Erreur lors de l'attribution du salarié">
              <div class="text-center">
                <p>Une erreur s'est produite lors de l'attribution du salarié au conseiller.</p>
                <b-button class="mt-3" variant="outline-success" @click="hideGlobalAPILoadingErrorModal">Fermer</b-button>
              </div>
            </b-modal>

            <!-- Salarie Attributed -->
            <b-modal ref="attributedSalarieModal" hide-footer title="Votre salarié a bien été attribué">
              <div class="text-center">
                <p><strong>Votre salarié a bien été attribué</strong>. Le conseiller Audiens le verra dorénavant dans sa liste de salariés attribués </p>
                <b-button class="mt-3" variant="outline-success" @click="hideAttributedSalarieModal">Fermer</b-button>
              </div>
            </b-modal>

            <!-- SEE SALARIE MODAL -->
            <b-modal size="lg" ref="handleSalarieModal" hide-footer>
              <template v-slot:modal-title>
                <strong> Affectez un conseiller au salarié  </strong>
              </template>
              <div class="row" style="margin-top: 10px;">
                <div class="col-sm-12">
                  <strong> Prénom : </strong>
                  <span> {{ handleSalarieFirstName }} </span>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-sm-12">
                  <strong> Nom : </strong>
                  <span> {{ handleSalarieLastName }}</span>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-sm-12">
                  <strong> Email : </strong>
                  <span> {{ handleSalarieUsername }}</span>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-sm-12">
                  <strong> Date de naissance : </strong>
                  <span>{{ $date(handleSalarieBirthdate).format('DD/MM/YYYY') }}</span>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-sm-12">
                  <strong> Catégorie professionnelle :  </strong>
                  <span> {{ professionalCategoryRender(handleSalarieProfessionalCategory) }}</span>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-sm-12">
                  <strong> Employeur actuel :  </strong>
                  <span> {{ handleSalarieEmployeur }}</span>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-sm-12">
                  <strong> Muna du salarié :  </strong>
                  <span> {{ handleSalarieMuna }}</span>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <strong style="font-size: 1.3em;"> Statut du dossier  : {{ handleSalarieState }}
                  </strong>
                </div>
              </div>

              <hr>
              <div class="row mt-3">
                <div class="col-12">
                  <strong class="text-primary" v-if="handleSalarieEtatCivilHasBeenUpdated">
                    Dossier mis à jour par le salarié
                  </strong>
                  <strong class="text-danger" v-else>
                    Le salarié n'a pas encore renseigné ses informations
                  </strong>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-sm-12">
                  <strong>Nombre d'enfants : </strong>
                  <span> {{ handleSalarieNbChildren }}</span>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-sm-12">
                  <strong>A effectué son service militaire : </strong>
                  <span><booleanComponent v-bind:value="handleSalarieIsServiceMilitaire"></booleanComponent></span>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-sm-12">
                  <strong>A exercé une activité professionnelle à l'étranger : </strong>
                  <span><booleanComponent v-bind:value="handleSalarieIsActivityAbroad"></booleanComponent></span>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-sm-12">
                  <strong>Souhaite faire une demande de rachat de trimestres : </strong>
                  <span><booleanComponent v-bind:value="handleSalarieIsDemandeRachatTrimestres"></booleanComponent></span>
                </div>
              </div>

              <hr>

              <b-form>
                <b-form-group id="conseiller-group" label="Conseiller attribué" label-for="conseiller-input">
                  <b-form-select
                    v-model="handleSalarieConseiller" :options="conseillersRender"
                    value-field="id" text-field="title"
                    id="conseiller-input"
                    @input="$v.handleSalarieConseiller.$touch()"
                    :state="$v.handleSalarieConseiller.$dirty ? !$v.handleSalarieConseiller.$error : null">
                  </b-form-select>
                </b-form-group>

                <b-button
                  block :variant="attributeConseillerButtonStyle" class="mt-2"
                  @click='attributeConseiller'
                  :disabled="$v.handleSalarieConseiller.$invalid || attributeConseillerInProcess">
                    <b-spinner small label="attribute conseiller Spinner" v-if="attributeConseillerInProcess"></b-spinner> {{ attributeConseillerButtonText }}
                </b-button>
              </b-form>
            </b-modal>

            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#BB3634">
            </loading>

            <b-tabs v-model="tabsIndex" content-class="mt-3" active-nav-item-class="font-weight-bold text-primary">
              <b-tab >
                <template slot="title">
                  <span class="h4"> Salariés </span>
                </template>
                <div class="row">
                  <div class="col-12 text-center">
                    <em> Par défaut, il vous est présenté la liste des salariés qui
                      n'ont pas encore été attribués à un conseiller</em>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12 text-center">
                    <b-button
                      block variant="outline-danger" class="mt-2"
                      @click='filterSalariesNotAttribute'>
                          Filtrer les salariés qui n'ont pas été attribué à un conseiller
                    </b-button>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-sm-6 text-center align-self-center">
                    <b-form-group id="state-group" label="Choisir l'état du salarié" label-for="state-input">
                      <b-form-select
                        v-model="state"
                        id="state-input"
                        :options='stateArrayForm'>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6 text-center align-self-center">
                    <b-button
                      block variant="outline-danger" class="mt-2"
                      @click='filterSalariesByState'>
                          Filtrer les salariés selon l'état
                    </b-button>
                  </div>
                </div>
                <b-table
                  responsive class="align-middle text-center mt-4 cursor-table"
                  headVariant="light" hover
                  :tbody-tr-class="arrayRowClass"
                  :fields="salariesRenderFields"
                  :items="salariesRender"
                  @row-clicked="salarieRowClicked"
                  >
                  <template v-slot:cell(birthdate)="data">
                    {{ $date(data.value).format("DD/MM/YYYY") }}
                  </template>
                </b-table>

                <b-button
                  v-if="getAllSalariesUrl && isAttributeFilter"
                  block variant="outline-primary" class="mt-2"
                  @click='getAllSalariesToAttribute'>
                    Voir plus
                </b-button>

                <b-button
                  v-if="getAllSalariesByStateUrl && isStateFilter"
                  block variant="outline-primary" class="mt-2"
                  @click='getAllSalariesByState(true)'>
                    Voir plus
                </b-button>
              </b-tab>

              <b-tab >
                <template slot="title">
                  <span class="h4"> Rendez-vous </span>
                </template>
                <ManagerAppointmentDashboard />
              </b-tab>

            </b-tabs>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import { APIManagerConnected } from '@/api/APIManagerConnected'
import formTitleComponent from '@/components/formTitleComponent'
import booleanComponent from '@/components/booleanComponent'

import ManagerAppointmentDashboard from '@/components/ManagerAppointmentDashboard'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


const apiManagerConnected = new APIManagerConnected()

export default {
  name: 'ManagerSalarieDashboard',
  components: {
    formTitleComponent,
    booleanComponent,
    ManagerAppointmentDashboard,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      tabsIndex: 0,

      conseillers: [],
      conseillersLoading: false,
      conseillersRender: [],

      salaries: [],
      salariesLoading: false,
      salariesRender: [],
      salariesRenderFields: [
        { key: "last_name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "first_name", label: "Prénom", tdClass: 'ui-helper-center', sortable: true},
        { key: "birthdate", label: "Date de naissance", tdClass: 'ui-helper-center' },
        { key: "employeur", label: "Employeur", tdClass: 'ui-helper-center', sortable: true},
        { key: "state", label: "Etat", tdClass: 'ui-helper-center' },
        { key: "conseiller", label: "Conseiller", tdClass: 'minWidthTd ui-helper-center', sortable: true },
      ],

      baseGetAllSalariesUrl: apiBaseUrl + `/get-all-salaries-to-attribute/`,
      getAllSalariesUrl: apiBaseUrl + `/get-all-salaries-to-attribute/`,
      baseGetAllSalariesByStateUrl: apiBaseUrl + `/get-all-salaries-by-state/`,
      getAllSalariesByStateUrl: apiBaseUrl + `/get-all-salaries-by-state/`,

      isAttributeFilter: true,
      isStateFilter: false,

      state: '2ONGOING',
      stateArrayForm: [
        { text: 'Créé par le salarié', value: '1CREATED' },
        { text: 'A valider par un conseiller', value: '2ONGOING' },
        { text: 'Profil refusée', value: '3REFUSED' },
        { text: 'Validé par le conseiller : bri en construction', value: '4VALIDATED' },
        { text: 'BRI mis à disposition', value: '5BRI' },
        { text: 'Rendez-vous pris par le salarié', value: '6RDV' },
      ],

      handleSalarieId: 0,
      handleSalarieFirstName: '',
      handleSalarieLastName: '',
      handleSalarieUsername: '',
      handleSalarieBirthdate: '',
      handleSalarieProfessionalCategory: '',
      handleSalarieEmployeur: '',
      handleSalarieMuna: '',
      handleSalarieState: '',
      handleSalarieNbChildren: '',
      handleSalarieIsServiceMilitaire: false,
      handleSalarieIsActivityAbroad: false,
      handleSalarieIsDemandeRachatTrimestres: false,

      handleSalarieEtatCivilHasBeenUpdated: false,

      handleSalarieConseiller: '',

      attributeConseillerButtonText: 'Attribuer un nouveau conseiller',
      attributeConseillerButtonStyle: 'outline-primary',
      attributeConseillerInProcess: false
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.salariesLoading || this.conseillersLoading) {
        return true
      }
      return false
    }
  },
  validations: {
    handleSalarieConseiller: {
      required,
    },
  },
  mounted: function() {
    this.getAllConseillers()
    this.getAllSalariesToAttribute()
  },
  watch: {
    salaries: function (newSalaries) {
      if (newSalaries.length == 0) {
        this.salariesRender = []
      }
      else {
        var final_array = [
          {
            'id': newSalaries[0].id,
            'first_name': newSalaries[0].user.first_name,
            'last_name': newSalaries[0].user.last_name,
            'username': newSalaries[0].user.username,
            'birthdate': newSalaries[0].birthdate,
            'professional_category': newSalaries[0].professional_category,
            'employeur': newSalaries[0].employeur,
            'muna': newSalaries[0].muna,
            'state': this.stateRender(newSalaries[0].state),

            'etat_civil': {
              'nb_children': newSalaries[0].etat_civil.nb_children,
              'is_service_militaire': newSalaries[0].etat_civil.is_service_militaire,
              'is_activity_abroad': newSalaries[0].etat_civil.is_activity_abroad,
              'is_demande_rachat_trimestres': newSalaries[0].etat_civil.is_demande_rachat_trimestres,
              'has_been_updated': newSalaries[0].etat_civil.has_been_updated,
            },

          }
        ]
        if (newSalaries[0].conseiller) {
          final_array[0]['conseiller'] = newSalaries[0].conseiller.user.first_name + ' ' + newSalaries[0].conseiller.user.last_name
          final_array[0]['conseiller_id'] = newSalaries[0].conseiller.id
        }
        else {
          final_array[0]['conseiller'] = "Aucun conseiller"
          final_array[0]['conseiller_id'] = ''
        }
        for (var i = 1; i < newSalaries.length; i++) {
          final_array.push(
            {
              'id': newSalaries[i].id,
              'first_name': newSalaries[i].user.first_name,
              'last_name': newSalaries[i].user.last_name,
              'username': newSalaries[i].user.username,
              'birthdate': newSalaries[i].birthdate,
              'professional_category': newSalaries[i].professional_category,
              'employeur': newSalaries[i].employeur,
              'muna': newSalaries[i].muna,
              'state': this.stateRender(newSalaries[i].state),

              'etat_civil': {
                'nb_children': newSalaries[i].etat_civil.nb_children,
                'is_service_militaire': newSalaries[i].etat_civil.is_service_militaire,
                'is_activity_abroad': newSalaries[i].etat_civil.is_activity_abroad,
                'is_demande_rachat_trimestres': newSalaries[i].etat_civil.is_demande_rachat_trimestres,
                'has_been_updated': newSalaries[i].etat_civil.has_been_updated,
              }

            }
          )
          if (newSalaries[i].conseiller) {
            final_array[i]['conseiller'] = newSalaries[i].conseiller.user.first_name + ' ' + newSalaries[i].conseiller.user.last_name
            final_array[i]['conseiller_id'] = newSalaries[i].conseiller.id
          }
          else {
            final_array[i]['conseiller'] = "Aucun conseiller"
            final_array[i]['conseiller_id'] = ''
          }
        }
        this.salariesRender = final_array
      }
    },

    conseillers: function (newConseillers) {
      if (newConseillers.length == 0) {
        this.conseillersRender = []
      }
      else {
        var final_array = [
          {
            'id': newConseillers[0].id,
            'title': newConseillers[0].user.first_name + ' ' + newConseillers[0].user.last_name,
          }
        ]
        for (var i = 1; i < newConseillers.length; i++) {
          final_array.push(
            {
            'id': newConseillers[i].id,
            'title': newConseillers[i].user.first_name +  ' ' + newConseillers[i].user.last_name,
          }
          )
        }
        this.conseillersRender = final_array
      }
    },
  },
  methods: {
    getAllSalariesToAttribute () {
      this.salariesLoading = true
      apiManagerConnected.getAllSalariesToAttribute(this.token, this.getAllSalariesUrl)
      .then((result) => {
        this.salaries = this.salaries.concat(result.data.results)
        this.getAllSalariesUrl = result.data.next
        this.isAttributeFilter = true
        this.isStateFilter = false
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.salariesLoading = false
      })
    },

    getAllSalariesByState (isNext) {
      this.salariesLoading = true
      var url = this.getAllSalariesByStateUrl + this.state
      if (isNext) {
        url = this.getAllSalariesByStateUrl
      }
      apiManagerConnected.getAllSalariesByState(this.token, url)
      .then((result) => {
        this.salaries = this.salaries.concat(result.data.results)
        this.getAllSalariesByStateUrl = result.data.next
        this.isAttributeFilter = false
        this.isStateFilter = true
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.salariesLoading = false
      })
    },

    getAllConseillers () {
      this.conseillersLoading = true
      apiManagerConnected.getAllConseillers(this.token)
      .then((result) => {
        this.conseillers = result.data
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.conseillersLoading = false
      })
    },

    attributeConseiller () {
      this.attributeConseillerInProcess = true
      this.attributeConseillerButtonText = "Attribution en cours"
      this.attributeConseillerButtonStyle = 'secondary'
      apiManagerConnected.AttributeConseillerToSalarie(this.token, this.handleSalarieId, this.handleSalarieConseiller)
      .then(() => {
        this.salaries = []
        this.getAllSalariesUrl = this.baseGetAllSalariesUrl
        this.getAllSalariesToAttribute()
        this.$refs.attributedSalarieModal.show()
      })
      .catch(() => {
        this.$refs.globalAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.$refs.handleSalarieModal.hide()
        this.attributeConseillerInProcess = false
        this.attributeConseillerButtonText = "Attribuer un nouveau conseiller"
        this.attributeConseillerButtonStyle = 'outline-primary'
      })

    },

    filterSalariesNotAttribute () {
      this.getAllSalariesUrl = this.baseGetAllSalariesUrl
      this.salaries = []
      this.getAllSalariesToAttribute()
    },

    filterSalariesByState () {
      this.getAllSalariesByStateUrl = this.baseGetAllSalariesByStateUrl
      this.salaries = []
      this.getAllSalariesByState(false)
    },

    arrayRowClass(item) {
      if (!item) return
      if (item.conseiller === 'Aucun conseiller') {
        return 'table-danger'
      }
      else {
        return
      }
    },

    salarieRowClicked(item) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      this.handleSalarieId = item['id']
      this.handleSalarieFirstName = item['first_name']
      this.handleSalarieLastName = item['last_name']
      this.handleSalarieUsername = item['username']
      this.handleSalarieBirthdate = item['day']
      this.handleSalarieProfessionalCategory = item['professional_category']
      this.handleSalarieEmployeur = item['employeur']
      this.handleSalarieMuna = item['muna']
      this.handleSalarieState = item['state']
      this.handleSalarieNbChildren = item['etat_civil']['nb_children']
      this.handleSalarieIsServiceMilitaire = item['etat_civil']['is_service_militaire']
      this.handleSalarieIsActivityAbroad = item['etat_civil']['is_activity_abroad']
      this.handleSalarieIsDemandeRachatTrimestres = item['etat_civil']['is_demande_rachat_trimestres']

      this.handleSalarieEtatCivilHasBeenUpdated = item['etat_civil']['has_been_updated']

      this.handleSalarieConseiller = item['conseiller_id']

      this.$refs.handleSalarieModal.show()
    },

    stateRender(state) {
      switch (state) {
        case '1CREATED':
          return 'Créé'
        case '2ONGOING':
          return 'Profil à valider'
        case '3REFUSED':
          return 'Profil refusé'
        case '4VALIDATED':
          return 'Profil validé, BRI à charger'
        case '5BRI':
          return 'BRI chargé'
        case '6RDV':
          return 'Rendez-vous pris par le salarié'
      }
      return 'Erreur'
    },

    professionalCategoryRender(professionalCategory) {
      switch (professionalCategory) {
        case 'SAL':
          return 'Salarié permanent'
        case 'ART':
          return 'Artiste et technicien du spectacle'
        case 'POL':
          return 'Indemnisé Pôle Emploi'
        case 'AUT':
          return 'Autres'
      }
      return 'Erreur'
    },

    goToLogin () {
      this.$router.push('/login')
    },

    hideGlobalAPILoadingErrorModal () {
      this.$refs.globalAPILoadingErrorModal.hide()
    },

    hideAttributedSalarieModal () {
      this.$refs.attributedSalarieModal.hide()
    }
  }
}
</script>

<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}

</style>
