<template>
  <div class="ManagerAppointmentDashboard" >


    <!-- ERROR MODAL -->
    <b-modal id="getAllAppointmentsAPILoadingErrorModal" ref="getAllAppointmentsAPILoadingErrorModal" hide-footer
    title="Erreur lors du chargement des rendez-vous">
      <div class="text-center">
        <p>Une erreur s'est produite lors du chargement des rendez-vous.</p>
        <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('getAllAppointmentsAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <b-modal id="getAllConseillersAPILoadingErrorModal" ref="getAllConseillersAPILoadingErrorModal" hide-footer
    title="Erreur lors du chargement des conseillers">
      <div class="text-center">
        <p>Une erreur s'est produite lors du chargement des conseillers.</p>
        <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('getAllConseillersAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <b-modal ref="attributeConseillerToAppointmentAPILoadingErrorModal" hide-footer title="Erreur lors de l'attribution du rendez-vous">
      <div class="text-center">
        <p>Une erreur s'est produite lors de l'attribution du rendez-vous au conseiller.</p>
        <b-button class="mt-3" variant="outline-success" @click="hideAttributeConseillerToAppointmentAPILoadingErrorModal">Fermer</b-button>
      </div>
    </b-modal>

    <!-- Appointment Attributed -->
    <b-modal id="attributedAppointmentModal" ref="attributedAppointmentModal" hide-footer title="Votre rendez-vous a bien été attribué">
      <div class="text-center">
        <p>
          <strong>Votre rendez-vous a bien été attribué</strong>.
          Le conseiller Audiens le verra dorénavant dans sa liste de rendez-vous attribués
        </p>
        <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('attributedAppointmentModal')">Fermer</b-button>
      </div>
    </b-modal>

    <!-- SEE SALARIE MODAL -->
    <b-modal size="lg" ref="handleAppointmentModal" hide-footer>
      <template v-slot:modal-title>
        <strong> Affectez un conseiller au rendez-vous  </strong>
      </template>
      <div class="row" style="margin-top: 10px;">
        <div class="col-sm-12">
          <strong> Prénom : </strong>
          <span> {{ handleAppointmentSalarieFirstName }} </span>
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="col-sm-12">
          <strong> Nom : </strong>
          <span> {{ handleAppointmentSalarieLastName }}</span>
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="col-sm-12">
          <strong> Email : </strong>
          <span> {{ handleAppointmentSalarieUsername }}</span>
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="col-sm-12">
          <strong> Date du rendez-vous : </strong>
          <span>{{ $date(handleAppointmentDay).format('DD/MM/YYYY)') }}</span>
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="col-sm-12">
          <strong> Horaires du rendez-vous : </strong>
          <span>De {{ handleAppointmentStart }} heures à {{ handleAppointmentEnd }}</span>
        </div>
      </div>

      <hr>

      <b-form>
        <b-form-group id="conseiller-group" label="Conseiller attribué" label-for="conseiller-input">
          <b-form-select
            v-model="handleAppointmentConseiller" :options="conseillersRender"
            value-field="id" text-field="title"
            id="conseiller-input"
            @input="$v.handleAppointmentConseiller.$touch()"
            :state="$v.handleAppointmentConseiller.$dirty ? !$v.handleAppointmentConseiller.$error : null">
          </b-form-select>
        </b-form-group>

        <b-button
          block :variant="attributeAppointmentToConseillerButtonStyle" class="mt-2"
          @click='attributeAppointmentToConseiller'
          :disabled="$v.handleAppointmentConseiller.$invalid || attributeAppointmentToConseillerInProcess">
            <b-spinner small label="attribute conseiller Spinner" v-if="attributeAppointmentToConseillerInProcess"></b-spinner>
            {{ attributeAppointmentToConseillerButtonText }}
        </b-button>
      </b-form>
    </b-modal>


    <b-table
      responsive class="align-middle text-center mt-4 cursor-table"
      headVariant="light" hover
      :tbody-tr-class="arrayRowClass"
      :fields="appointmentsRenderFields"
      :items="appointmentsRender"
      @row-clicked="appointmentRowClicked"
      >
      <template v-slot:cell(day)="data">
        {{ $date(data.value).format("DD/MM/YYYY") }}
      </template>
    </b-table>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { APIManagerConnected } from '@/api/APIManagerConnected'
const apiManagerConnected = new APIManagerConnected()
export default {
  name: 'ManagerAppointmentDashboard',
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      conseillers: [],
      conseillersRender: [],
      appointments: [],
      appointmentsRender: [],
      appointmentsRenderFields: [
        { key: "first_name", label: "Prénom", tdClass: 'ui-helper-center', sortable: true},
        { key: "last_name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "day", label: "Date du rendez-vous", tdClass: 'ui-helper-center', sortable: true},
        { key: "start", label: "Heure de début", tdClass: 'ui-helper-center' },
        { key: "end", label: "Heure de fin", tdClass: 'minWidthTd ui-helper-center' },
        { key: "conseiller", label: "Conseiller", tdClass: 'minWidthTd ui-helper-center', sortable: true },
      ],
      handleAppointmentId: 0,
      handleAppointmentSalarieFirstName: '',
      handleAppointmentSalarieLastName: '',
      handleAppointmentSalarieUsername: '',
      handleAppointmentDay: '',
      handleAppointmentStart: '',
      handleAppointmentEnd: '',
      handleAppointmentConseiller: '',
      attributeAppointmentToConseillerButtonText: 'Attribuer un nouveau conseiller',
      attributeAppointmentToConseillerButtonStyle: 'outline-primary',
      attributeAppointmentToConseillerInProcess: false
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
  },
  validations: {
    handleAppointmentConseiller: {
      required,
    },
  },
  mounted: function() {
    this.getAllConseillers()
    this.getAllAppointments()
  },
  watch: {
    appointments: function (newAppointments) {
      if (newAppointments.length == 0) {
        this.appointmentsRender = []
      }
      else {
        var final_array = [
          {
            'id': newAppointments[0].id,
            'first_name': newAppointments[0].salarie.user.first_name,
            'last_name': newAppointments[0].salarie.user.last_name,
            'username': newAppointments[0].salarie.user.username,
            'day': newAppointments[0].day,
            'start': newAppointments[0].start,
            'end': newAppointments[0].end,
          }
        ]
        if (newAppointments[0].conseiller) {
          final_array[0]['conseiller'] = newAppointments[0].conseiller.user.first_name + ' ' + newAppointments[0].conseiller.user.last_name
          final_array[0]['conseiller_id'] = newAppointments[0].conseiller.id
        }
        else {
          final_array[0]['conseiller'] = "Aucun conseiller"
          final_array[0]['conseiller_id'] = ''
        }
        for (var i = 1; i < newAppointments.length; i++) {
          final_array.push(
            {
              'id': newAppointments[i].id,
              'first_name': newAppointments[i].salarie.user.first_name,
              'last_name': newAppointments[i].salarie.user.last_name,
              'username': newAppointments[i].salarie.user.username,
              'day': newAppointments[i].day,
              'start': newAppointments[i].start,
              'end': newAppointments[i].end,
            }
          )
          if (newAppointments[i].conseiller) {
            final_array[i]['conseiller'] = newAppointments[i].conseiller.user.first_name + ' ' + newAppointments[i].conseiller.user.last_name
            final_array[i]['conseiller_id'] = newAppointments[i].conseiller.id
          }
          else {
            final_array[i]['conseiller'] = "Aucun conseiller"
            final_array[i]['conseiller_id'] = ''
          }
        }
        this.appointmentsRender = final_array
      }
    },
    conseillers: function (newConseillers) {
      if (newConseillers.length == 0) {
        this.conseillersRender = []
      }
      else {
        var final_array = [
          {
            'id': newConseillers[0].id,
            'title': newConseillers[0].user.first_name + ' ' + newConseillers[0].user.last_name,
          }
        ]
        for (var i = 1; i < newConseillers.length; i++) {
          final_array.push(
            {
            'id': newConseillers[i].id,
            'title': newConseillers[i].user.first_name +  ' ' + newConseillers[i].user.last_name,
          }
          )
        }
        this.conseillersRender = final_array
      }
    },
  },
  methods: {
    getAllAppointments () {
      apiManagerConnected.getAllAppointments(this.token)
      .then((result) => {
        this.appointments = result.data
      })
      .catch(() => {
        this.$refs.getAllAppointmentsAPILoadingErrorModal.show()
      })
    },
    getAllConseillers () {
      apiManagerConnected.getAllConseillers(this.token)
      .then((result) => {
        this.conseillers = result.data
      })
      .catch(() => {
        this.$refs.getAllConseillersAPILoadingErrorModal.show()
      })
    },
    attributeAppointmentToConseiller () {
      this.attributeAppointmentToConseillerInProcess = true
      this.attributeAppointmentToConseillerButtonText = "Attribution en cours"
      this.attributeAppointmentToConseillerButtonStyle = 'secondary'
      apiManagerConnected.AttributeAppointmentToConseiller(this.token, this.handleAppointmentId, this.handleAppointmentConseiller)
      .then(() => {
        this.getAllAppointments()
        this.$refs.attributedAppointmentModal.show()
      })
      .catch(() => {
        this.$refs.attributeConseillerToAppointmentAPILoadingErrorModal.show()
      })
      .finally(() => {
        this.$refs.handleAppointmentModal.hide()
        this.attributeAppointmentToConseillerInProcess = false
        this.attributeAppointmentToConseillerButtonText = "Attribuer un nouveau conseiller"
        this.attributeAppointmentToConseillerButtonStyle = 'outline-primary'
      })
    },
    arrayRowClass(item) {
      if (!item) return
      if (item.conseiller === 'Aucun conseiller') {
        return 'table-danger'
      }
      else {
        return
      }
    },
    appointmentRowClicked(item) {
      // 'record' will be the row data from items
      // `index` will be the visible row number (available in the v-model 'shownItems')
      this.handleAppointmentId = item['id']
      this.handleAppointmentSalarieFirstName = item['first_name']
      this.handleAppointmentSalarieLastName = item['last_name']
      this.handleAppointmentSalarieUsername = item['username']
      this.handleAppointmentDay = item['day']
      this.handleAppointmentStart = item['start']
      this.handleAppointmentEnd = item['end']
      this.handleAppointmentConseiller = item['conseiller_id']
      this.$refs.handleAppointmentModal.show()
    },
    hideAttributeConseillerToAppointmentAPILoadingErrorModal () {
      this.$refs.attributeConseillerToAppointmentAPILoadingErrorModal.hide()
    }
  }
}
</script>

<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}
</style>
